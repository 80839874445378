import '@fontsource/roboto-condensed/300.css';
import '@fontsource/roboto-condensed/400.css';
import '@fontsource/roboto-condensed/700.css';
import { ThemeOptions } from '@mui/material/styles';

export const typography: ThemeOptions = {
	typography: {
		htmlFontSize: 16,
		fontFamily: [
			'Roboto Condensed',
			'Open Sans',
			'Helvetica Neue',
			'Helvetica',
			'Arial',
			'sans-serif',
		].join(','),
		h1: {
			fontSize: 72,
			fontWeight: 700,
		},
		h2: {
			fontSize: 52,
			fontWeight: 700,
		},
		h3: {
			fontSize: 32,
			fontWeight: 700,
		},
		h4: {
			fontSize: 26,
			fontWeight: 700,
		},
		h5: {
			fontSize: 20,
			fontWeight: 700,
		},
		h6: {
			fontSize: 17,
			fontWeight: 700,
		},
		subtitle1: {
			fontSize: 18,
			fontWeight: 700,
		},
		subtitle2: {
			fontSize: 18,
			fontWeight: 700,
		},
		body1: {
			fontSize: 18,
			fontWeight: 400,
		},
		body2: {
			fontSize: 12,
			fontWeight: 400,
		},
		button: {
			fontSize: 15,
			fontWeight: 400,
			textTransform: 'none',
		},
		caption: {
			fontSize: 12,
			fontWeight: 400,
		},
		strong: {
			fontWeight: 700,
		},
		overline: {
			fontSize: 13,
			textTransform: 'uppercase',
			fontWeight: 700,
			letterSpacing: 1,
		},
	},
};

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		inline: true;
	}
}
