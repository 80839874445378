import { Alert } from '@/styles/DiValSafety/Alert';
import { Button } from '@/styles/DiValSafety/Button';
import { Checkbox } from '@/styles/DiValSafety/Checkbox';
import { Container } from '@/styles/DiValSafety/Container';
import { CssBaseline } from '@/styles/DiValSafety/CssBaseline';
import { Link } from '@/styles/DiValSafety/Link';
import { Pagination } from '@/styles/DiValSafety/Pagination';
import { Radio } from '@/styles/DiValSafety/Radio';
import { Select } from '@/styles/DiValSafety/Select';
import { TextField } from '@/styles/DiValSafety/TextField';
import { palette } from '@/styles/DiValSafety/palette';
import { typography } from '@/styles/DiValSafety/type';
import { Icon } from '@/styles/Ruby/Icon';
import { shape } from '@/styles/Ruby/shape';
import { ThemeManifestTheme } from '@/styles/manifest';

export const DiValSafetyTheme: ThemeManifestTheme = {
	inheritFrom: 'Default',
	components: [
		palette,
		typography,
		shape,
		TextField,
		Button,
		Checkbox,
		Icon,
		Radio,
		Link,
		Pagination,
		Alert,
		Container,
		Select,
		CssBaseline,
	],
};
