/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ID } from '@/data/types/Basic';
import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

export const contentManifestCustom: Record<string, ComponentType<{ id: ID }>> = {
	CollectCarriers: dynamic(() =>
		import('@/components/content/CollectCarriers').then((mod) => mod.CollectCarriers)
	),
	ContactUs: dynamic(() => import('@/components/content/ContactUs').then((mod) => mod.ContactUs)),
	CreditCards: dynamic(() =>
		import('@/components/content/CreditCards').then((mod) => mod.CreditCards)
	),
	DivalCategoryPage: dynamic(() =>
		import('@/components/content/DiValCategoryPage').then((mod) => mod.DiValCategoryPage)
	),
	PlpTopEspot: dynamic(() =>
		import('@/components/content/PLPTopEspot').then((mod) => mod.PLPTopEspot)
	),
	MinimizeHeader: dynamic(() =>
		import('@/components/content/MinimizeHeader').then((mod) => mod.MinimizeHeader)
	),
	Quotes: dynamic(() => import('@/components/content/Quotes').then((mod) => mod.Quotes)),
	QuoteDetails: dynamic(() =>
		import('@/components/content/QuoteDetails').then((mod) => mod.QuoteDetails)
	),
};
