/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ItemWrap } from '@/components/layouts/ItemWrap';
import { LayoutSlot } from '@/components/layouts/LayoutSlot';
import { layoutGridHeightSX } from '@/components/layouts/styles/gridHeight';
import { layoutMainSX } from '@/components/layouts/styles/main';
import { layoutStackHeightSX } from '@/components/layouts/styles/stackHeight';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { Layout } from '@/data/types/Layout';
import { Container, Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useEffect, useState } from 'react';

export const AsideExtendedPlp: FC<{ layout: Layout }> = ({ layout }) => {
	const {
		dimensions: { contentSpacing },
	} = useTheme();
	const router = useNextRouter();
	const [isFixed, setIsFixed] = useState(false);
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY >= 150) {
				setIsFixed(true);
			} else {
				setIsFixed(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const { scrollID } = router.query;
	useEffect(() => {
		if (scrollID) {
			const productElement = document.getElementById(`${scrollID}`);
			if (productElement) {
				productElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	}, [scrollID]);

	return layout ? (
		<>
			<LayoutSlot slot={layout.slots.header} />
			<Grid
				container
				direction="column"
				component="main"
				spacing={contentSpacing}
				sx={layoutMainSX(layout.slots.first?.length || 0)}
			>
				<Grid item xs="auto">
					<LayoutSlot slot={layout.slots.first} ItemWrap={ItemWrap} />
				</Grid>
				<Grid item xs>
					<Container component="section" sx={layoutStackHeightSX}>
						<Grid container spacing={contentSpacing} sx={layoutStackHeightSX}>
							<Grid
								item
								component="aside"
								xs={12}
								md={3}
								aria-label={layout.slots.aside?.at(0)?.name}
							>
								<Stack
									sx={{
										'& .MuiAppBar-positionFixed': {
											position: isFixed ? 'fixed' : 'relative',
											top: isFixed ? 0 : 'auto',
											transition: 'top 0.3s ease, background-color 0.3s ease',
											zIndex: 1000,
										},
										'.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorBottom':
											{
												top: 0,
											},
									}}
									gap={contentSpacing}
								>
									<LayoutSlot slot={layout.slots.aside} />
								</Stack>
							</Grid>
							<Grid item component="section" xs={12} md={9} sx={layoutGridHeightSX}>
								<Stack gap={contentSpacing} sx={layoutStackHeightSX}>
									<LayoutSlot slot={layout.slots.second} />
								</Stack>
							</Grid>
						</Grid>
					</Container>
				</Grid>
				<Grid item xs="auto">
					<LayoutSlot slot={layout.slots.footer} />
				</Grid>
			</Grid>
		</>
	) : null;
};
