/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ItemWrap } from '@/components/layouts/ItemWrap';
import { LayoutSlot } from '@/components/layouts/LayoutSlot';
import { layoutMainSX } from '@/components/layouts/styles/main';
import { Layout } from '@/data/types/Layout';
import { Container, Grid, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useEffect, useState } from 'react';

export const Aside: FC<{ layout: Layout }> = ({ layout }) => {
	const {
		dimensions: { contentSpacing },
	} = useTheme();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const [isFixed, setIsFixed] = useState(false);
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY >= 150) {
				setIsFixed(true);
			} else {
				setIsFixed(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return layout ? (
		<>
			{isMobile ? (
				<Stack
					sx={{
						'& .MuiAppBar-positionFixed': {
							position: isFixed ? 'fixed' : 'relative',
							top: isFixed ? 0 : 'auto',
							transition: 'top 0.3s ease, background-color 0.3s ease',
							zIndex: 1000,
						},
						'.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorBottom':
							{
								top: 0,
							},
					}}
					gap={contentSpacing}
				>
					<LayoutSlot slot={layout.slots.aside} />
				</Stack>
			) : null}
			<LayoutSlot slot={layout.slots.header} />
			<Stack
				component="main"
				gap={contentSpacing}
				sx={layoutMainSX(layout.slots.first?.length || 0)}
			>
				<LayoutSlot slot={layout.slots.first} ItemWrap={ItemWrap} />
				<Container component="section">
					<Grid container spacing={contentSpacing}>
						<Grid
							item
							component="aside"
							xs={12}
							md={3}
							aria-label={layout.slots.aside?.at(0)?.name}
						>
							{!isMobile ? (
								<Stack gap={contentSpacing}>
									<LayoutSlot slot={layout.slots.aside} />
								</Stack>
							) : null}
						</Grid>
						<Grid item component="section" xs={12} md={9}>
							<Stack gap={contentSpacing}>
								<LayoutSlot slot={layout.slots.second} />
							</Stack>
						</Grid>
					</Grid>
				</Container>
				<LayoutSlot slot={layout.slots.footer} />
			</Stack>
		</>
	) : null;
};
