/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { DiValSafetyTheme } from '@/styles/DiValSafety/theme';
import { ThemeManifest } from '@/styles/manifest';

export const themeManifestCustom: ThemeManifest = {
	defaultTheme: 'DiValSafety',
	themes: { DiValSafety: DiValSafetyTheme },
};
