import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Container: ThemeOptions = {
	components: {
		MuiContainer: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						maxWidth: { lg: '1920px' },
					},
				}),
			},
		},
	},
};
