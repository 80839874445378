/* eslint-disable @typescript-eslint/no-empty-function */
import { useNextRouter } from '@/data/Content/_NextRouter';

type CallAPIParams = {
	payload: any;
	endPointUrl: string;
	onSuccess?: () => void;
	onError?: () => void;
};

const usePunchOutTransferCart = () => {
	const router = useNextRouter();

	const callAPI = async ({
		payload,
		endPointUrl,
		onSuccess = () => {},
		onError = () => {},
	}: CallAPIParams) => {
		try {
			const res = await fetch('/api/punchOut', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					payload,
					punchOutEndPoint: endPointUrl,
				}),
			});
			const data = await res.json();
			console.log('PunchOut Res ====> ', data);
			if (data?.redirect_url) {
				router.push(data?.redirect_url);
			}
			onSuccess();
		} catch (err) {
			console.log('err', err);
			onError();
		}
	};

	return { callAPI };
};

export default usePunchOutTransferCart;
