import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Link: ThemeOptions = {
	components: {
		MuiLink: {
			defaultProps: {
				// The props to change the default for.
				underline: 'hover',
			},
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						color: 'link.main',
						'&:hover': {
							color: 'link.hover',
						},
						'&:active, &:focus': {
							color: 'link.active',
						},
						'&.disabled': {
							color: 'text.disabled',
						},
					},
				}),
			},
		},
	},
};
