/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Pagination: ThemeOptions = {
	components: {
		MuiPagination: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						'ul li:first-of-type button, ul li:last-of-type button': {
							border: 0,
						},
						'.MuiPaginationItem-rounded': {
							border: '2px solid',
							borderColor: 'text.disabled',
							borderRadius: 0,
						},
						'.MuiPaginationItem-rounded.Mui-selected': {
							border: 0,
							backgroundColor: 'primary.main',
							color: '#fff',
						},
						'.MuiPaginationItem-root.MuiPaginationItem-ellipsis': {
							border: 'none',
						},
					},
				}),
			},
		},
	},
};
