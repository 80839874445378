import { ThemeOptions } from '@mui/material/styles';

export const palette: ThemeOptions = {
	palette: {
		primary: {
			light: '#ee5044',
			main: '#c8102e',
			dark: '#b30000',
			contrastText: '#fff',
		},
		secondary: {
			main: '#29333b',
			light: '#525c65',
			dark: '#000000',
			contrastText: '#fff',
		},
		text: {
			main: '#43484d',
			primary: '#292929',
			secondary: '#424242',
			disabled: '#a5a19a',
			highlight: '#f44336',
			alert: '#c10c0d',
			expandedMenu: '#ffffff',
			menu: '#eee',
			menuHover: '#fff',
			header: '#fff',
			footer: '#fff',
		},
		base: {
			main: '#43484d',
			contrastText: '#ffffff',
		},
		action: {
			hover: '#f7f5f2',
			disabled: '#a5a19a',
			disabledBackground: '#eee',
		},
		background: {
			main: '#333',
			default: '#f5f5f5',
			paper: '#ffffff',
			transparency: 'cc', // used to combine hex colors with transparency (00-ff), e.g., #ffffffcc
			contrastText: '#eee',
			extraLight: '#5e5e5e',
			light: '#515151',
			dark: '#212121',
			contrastTextHover: '#fff',
			gray: '#919191',
		},
		divider: '#5e5e5e',
		border: {
			dark: '#4c5256',
			alert: '#c10c0d',
			footer: '#888',
		},
		textField: {
			border: '#660b13',
			borderHovered: '#3a060b',
			background: '#fdfdfd',
			disabledBackground: '#ddcfcf',
		},
		textError: {
			error: '#FF0000',
		},
		button: {
			primary: '#2c0408',
			primaryHover: '#580911',
			secondary: '#ffffff',
			secondaryHover: '#fff4f4',
			contrastText: '#fdfdfd',
			disabled: '#ddcfcf',
			disabledText: '#a1a5ab',
		},
		checkbox: {
			border: '#006BB4',
			backgroundColor: '#29333b0a',
		},
		link: {
			main: '#006bb4',
			hover: '#006bb4',
			active: '#cf2031',
			secondary: '#ffffff',
		},
		forms: {
			outline: '#525c65',
			background: {
				main: '#eaf1f5',
				error: '#fff7f6',
			},
		},
		success: {
			main: '#2e7d32',
			dark: '#1b5e20',
		},
	},
} as ThemeOptions;
