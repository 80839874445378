import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Alert: ThemeOptions = {
	components: {
		MuiAlert: {
			styleOverrides: {
				message: getStyleOverrides({
					styles: {
						typography: 'body1',
					},
				}),
			},
		},
	},
};
