import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const TextField: ThemeOptions = {
	components: {
		MuiTextField: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {},
				}),
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				outlined: getStyleOverrides({
					styles: {
						transform: 'none',
						position: 'relative',
						mb: 0.25,
						pointerEvents: 'unset',

						'&.Mui-focused:not(.Mui-error)': {
							color: 'text.primary',
							'&.MuiFormLabel-colorSuccess': {
								color: 'success.dark',
							},
						},
						'&.MuiFormLabel-colorSuccess': {
							color: 'success.main',
						},
					},
				}),
				required: getStyleOverrides({
					styles: {
						'.MuiInputLabel-asterisk': {
							display: 'none',
						},
					},
				}),
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						backgroundColor: 'forms.background.main',

						legend: {
							width: '0px !important',
						},

						borderRadius: '6px',

						'&:hover': {
							'&:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
								borderColor: 'text.secondary',
							},
							'&.MuiInputBase-colorSuccess .MuiOutlinedInput-notchedOutline': {
								borderColor: 'success.main',
							},
						},

						'.MuiOutlinedInput-input': {
							padding: 0,
							fontWeight: 500,
							color: 'text.primary',
							height: '45px',
							backgroundColor: 'forms.background.main',
							borderRadius: '6px',
							transition: 'background-color .15s ease-in-out',

							'&:not(.MuiInputBase-inputMultiline):not(.MuiSelect-select):not(.MuiNativeSelect-select)':
								{
									padding: '0 16px',
								},

							'&.MuiSelect-select, &.MuiNativeSelect-select': {
								paddingLeft: '16px',
							},

							'&.MuiInputBase-inputMultiline': {
								padding: '16px',
							},
						},

						'.MuiOutlinedInput-notchedOutline': {
							borderWidth: '1px',
							borderColor: 'forms.outline',
							transition: 'box-shadow, border-color .15s ease-in-out',
						},

						'&.MuiInputBase-colorSuccess .MuiOutlinedInput-notchedOutline': {
							borderColor: 'success.main',
						},

						'&.Mui-focused': {
							'.MuiOutlinedInput-notchedOutline': {
								borderWidth: '1px',
							},
							'&:not(.Mui-error)': {
								'.MuiOutlinedInput-notchedOutline': {
									borderColor: '#000',
									boxShadow: '0 0 5px rgba(0, 107, 180, .5)',
								},
							},
							'&.MuiInputBase-colorSuccess .MuiOutlinedInput-notchedOutline': {
								borderColor: 'success.dark',
								boxShadow: '0 0 5px rgba(27, 94, 32, .5)',
							},
						},

						'.Mui-error': {
							'.MuiOutlinedInput-input': {
								backgroundColor: 'forms.background.error',
							},
						},
					},
				}),
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						backgroundColor: 'textField.background',
						'&:hover,&:focus': {
							borderColor: 'textField.borderHovered',
						},
					},
				}),
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						'.MuiOutlinedInput-root': {
							backgroundColor: 'forms.background.main',
							py: '8px',
							paddingLeft: '16px',
							height: '45px',
						},
					},
				}),
			},
		},
	},
};
