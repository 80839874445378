/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ItemWrap } from '@/components/layouts/ItemWrap';
import { LayoutSlot } from '@/components/layouts/LayoutSlot';
import { Layout } from '@/data/types/Layout';
import { Container, Grid, Stack } from '@mui/material';
import { FC } from 'react';

export const HomePage: FC<{ layout: Layout }> = ({ layout }) =>
	layout ? (
		<>
			<LayoutSlot slot={layout.slots.header} />
			<Stack component="main" direction="column" mt={2} spacing={2} useFlexGap>
				<Container>
					<Grid container spacing={2}>
						<Grid item xs={12} md={8}>
							<LayoutSlot slot={layout.slots.first} />
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							sx={{
								display: 'flex',
								flexDirection: { xs: 'column', sm: 'row', md: 'column' },
								justifyContent: 'space-between',
								gap: { xs: 2, md: 0 },
								'> *': {
									width: '100%',
								},
							}}
						>
							<LayoutSlot slot={layout.slots.second} />
						</Grid>
					</Grid>
				</Container>
				<LayoutSlot slot={layout.slots.third} ItemWrap={ItemWrap} />
				<LayoutSlot slot={layout.slots.fourth} />
				<LayoutSlot slot={layout.slots.fifth} ItemWrap={ItemWrap} />
				<LayoutSlot slot={layout.slots.footer} />
			</Stack>
		</>
	) : null;
