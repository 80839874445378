import { buttonVariants } from '@/styles/DiValSafety/Button/variants';
import { buttonVariantsStyles } from '@/styles/Ruby/Button/variantsStyles';
import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Button: ThemeOptions = {
	components: {
		MuiButtonBase: {
			defaultProps: {
				// The props to change the default for.
				disableRipple: false,
			},
		},
		MuiButton: {
			variants: buttonVariants,
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						py: 1,
						px: 2,
						borderRadius: '6px',
						minWidth: 'auto',
						typography: 'body1',
						boxShadow: 'none',
						'&.Mui-disabled': {
							color: 'button.disabledText',
						},
						'&.MuiButton-sizeMedium': {
							height: '45px',
						},
					},
					variants: buttonVariantsStyles,
				}),
				contained: getStyleOverrides({
					styles: {
						'&:hover': {
							boxShadow: 'none',
						},
					},
				}),
				outlined: getStyleOverrides({
					styles: {
						borderWidth: '2px',
						fontWeight: 500,
						'&:hover': {
							borderWidth: '2px',
						},
						'&.MuiButton-outlinedPrimary': {
							borderColor: 'primary.main',
							'&:hover': {
								borderColor: 'primary.dark',
								backgroundColor: 'primary.dark',
								color: 'white',
							},
							'&.Mui-disabled': {
								backgroundColor: 'action.disabledBackground',
								borderColor: 'action.disabledBackground',
								borderWidth: '2px',
							},
						},
						'&.MuiButton-outlinedSecondary': {
							borderColor: 'secondary.main',
							'&:hover': {
								borderColor: 'secondary.dark',
								backgroundColor: 'secondary.dark',
								color: 'white',
							},
							'&.Mui-disabled': {
								backgroundColor: 'action.disabledBackground',
								borderColor: 'action.disabledBackground',
								borderWidth: '2px',
							},
						},
					},
				}),
				text: getStyleOverrides({
					styles: {
						boxShadow: 'none',
						minWidth: 'unset',
						color: 'primary.main',
						'&:hover': {
							color: 'primary.dark',
						},
					},
				}),
			},
		},
	},
};
