import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Select: ThemeOptions = {
	components: {
		MuiNativeSelect: {
			styleOverrides: {
				select: getStyleOverrides({
					styles: {
						lineHeight: '45px',
					},
				}),
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: getStyleOverrides({
					styles: {
						lineHeight: '45px',
					},
				}),
			},
		},
	},
};
